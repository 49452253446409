import React from 'react';
import Link from './link';

const Layout = ({ children }) => {
  return (
    <div className="container font-mono">
      <main>{children}</main>
      <footer className="pb-8">
        © {new Date().getFullYear()}, Built with
        <Link href="https://www.gatsbyjs.org"> Gatsby </Link>
      </footer>
    </div>
  );
};

export default Layout;
