import React from 'react';

const Link = ({ children, href, ...props }) => {
  return (
    <a className="text-green-light" href={href} {...props}>
      {children}
    </a>
  );
};

export default Link;
